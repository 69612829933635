import React from 'react'
import Layout from '../layout/Layout'
import { ErrorPage } from '../components/ErrorPage'

export default function error404() {
  return (
    <Layout
      metaTitle="Transport drogowy"
      metaDescription="Jesteśmy firmą, która od wielu lat zapewnia Klientom krajowy i międzynarodowy transport drogowy w standardowym i specjalistycznym zakresie."
      slug="/"
      reverseHeaderColors
    >
      <ErrorPage />
    </Layout>
  )
}
