import styled from 'styled-components'

const Section = styled.section`
  padding: 120px 0 32px;

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 200px 0 40px;
  }
`

export { Section }
