import React from 'react'
import * as Styled from './ErrorPage.styles'
import { Row } from '../../layout/Grid'
import DoubleTitle from '../DoubleTitle'
import Button from '../Button'

export const ErrorPage = () => {
  return (
    <Styled.Section>
      <Row column>
        <DoubleTitle smallTitle="Error" title="Błąd 404" border="addon" />
        <p>Taka strona nie istnieje.</p>
        <Button page="/" primary>
          Wróć na stronę główną
        </Button>
      </Row>
    </Styled.Section>
  )
}
